let prefixoConteudo = "/conteudo";
const privilegioConteudo = [5];
import auth from "../middleware/auth";

export default [
  {
    path: prefixoConteudo + "/relatorio-questoes",
    name: "relatorio-questoes",
    component: () => import("../pages/conteudo/relatorioQuestoes/Inicio.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/mais-saber/relatorio-questoes",
    name: "relatorio-questoes-mais-saber",
    component: () => import("../pages/conteudo/relatorioQuestoesMaisSaber/Inicio.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/conteudos",
    name: "conteudos",
    component: () => import("../pages/conteudo/crud/conteudos/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/atividade/adicionar-editar-questao/:id_questao?",
    name: "adicionar-editar-questoes-atividade",
    component: () =>
      import(
        "../pages/conteudo/relatorioQuestoes/AdicionarEditarQuestaoAtividade.vue"
      ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/avaliacao/adicionar-editar-questao/:id_questao?",
    name: "adicionar-editar-questoes-avaliacao",
    component: () =>
      import(
        "../pages/conteudo/relatorioQuestoes/AdicionarEditarQuestaoAvaliacao.vue"
      ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/mais-saber/avaliacao/adicionar-editar-questao/:id_questao?",
    name: "adicionar-editar-questoes-avaliacao-mais-saber",
    component: () =>
      import(
        "../pages/conteudo/relatorioQuestoesMaisSaber/AdicionarEditarQuestaoAvaliacao.vue"
      ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },

  //cruds reds

  {
    path: prefixoConteudo + "/crud/saeb",
    name: "Crud-Saeb",
    component: () => import("../pages/conteudo/crud/saeb/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },

  {
    path: prefixoConteudo + "/crud/bncc",
    name: "Crud-BNCC",
    component: () => import("../pages/conteudo/crud/bncc/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },

  {
    path: prefixoConteudo + "/crud/objeto-conhecimento",
    name: "Crud-Objeto-Conhecimento",
    component: () =>
      import("../pages/conteudo/crud/objetoConhecimento/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },

  {
    path: prefixoConteudo + "/crud/habilidade",
    name: "Crud-Conteudo-Aberto",
    component: () => import("../pages/conteudo/crud/habilidade/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/crud/turmas",
    name: "Crud-Turmas",
    component: () => import("../pages/conteudo/crud/turmas/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/crud/banner-home",
    name: "Crud-BannerHome",
    component: () => import("../pages/conteudo/crud/bannerHome/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/crud/podcast",
    name: "Crud-Podcast",
    component: () => import("../pages/conteudo/crud/podcast/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/crud/audios-ingles",
    name: "Crud-Audio-ingles",
    component: () => import("../pages/conteudo/crud/audioIngles/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },

  {
    path: prefixoConteudo + "/crud/sequencia-didatica",
    name: "Crud-Sequencia-didatica",
    component: () =>
      import("../pages/conteudo/crud/sequenciaDidatica/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
    props: {
      tipo: "sequencia",
    },
  },
  {
    path: prefixoConteudo + "/crud/planejamentos",
    name: "Crud-Planejamentos",
    component: () =>
      import("../pages/conteudo/crud/sequenciaDidatica/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
    props: {
      tipo: "planejamento",
    },
  },
  {
    path: prefixoConteudo + "/crud/infograficos-mapas",
    name: "Crud-Infograficos",
    component: () =>
      import("../pages/conteudo/crud/infograficosMapas/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/crud/videos",
    name: "Crud-Videos",
    component: () => import("../pages/conteudo/crud/videos/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },

  {
    path: prefixoConteudo + "/crud/videoaulas-animacoes",
    name: "Crud-Videoaulas",
    component: () =>
      import("../pages/conteudo/crud/videoaulasAnimacoes/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/crud/projetos-integradores",
    name: "Crud-Projetos-Integradores",
    component: () =>
      import("../pages/conteudo/crud/sequenciaDidatica/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
    props: {
      tipo: "projeto_integrador",
    },
  },

  {
    path: prefixoConteudo + "/crud/audios-ingles",
    name: "Crud-Audios-Ingles",
    component: () => import("../pages/conteudo/crud/podcast/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
    props: {
      tipo: "audio_ingles",
    },
  },
  {
    path: prefixoConteudo + "/crud/livros",
    name: "Crud-Livros",
    component: () => import("../pages/conteudo/crud/livro/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/conteudo-aberto",
    name: "Conteudo-Aberto",
    component: () => import("../pages/conteudo/conteudoAberto/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/conteudo-aberto/adicionar-editar-conteudo",
    name: "Adicionar-Editar-conteudo",
    component: () =>
      import("../pages/conteudo/conteudoAberto/AdicionarEditarArtigo.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/crud/obras",
    name: "Crud-Obras",
    component: () => import("../pages/conteudo/crud/obra/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/crud/disciplinas",
    name: "Crud-Disciplinas",
    component: () => import("../pages/conteudo/crud/disciplina/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/crud/escolas",
    name: "Crud-Escolas",
    component: () => import("../pages/conteudo/crud/escola/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  // {
  //   path: prefixoConteudo + "/crud/municipios",
  //   name: "Crud-Municipio",
  //   component: () => import("../pages/conteudo/crud/municipio/Home.vue"),
  //   beforeEnter: auth,
  //   meta: {
  //     id_privilegio: privilegioConteudo,
  //   },
  // },
  {
    path: prefixoConteudo + "/crud/contratos",
    name: "Crud-Contratos",
    component: () => import("../pages/conteudo/crud/contrato/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/crud/usuarios",
    name: "Crud-Usuarios",
    component: () =>
      import("../pages/conteudo/crud/usuario/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },
  {
    path: prefixoConteudo + "/pnld",
    name: "Crud-Usuarios",
    component: () =>
      import("../pages/conteudo/crud/pnldCompartilhamento/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },

  //cruds assessoria

  {
    path: prefixoConteudo + "/crud/encontros-pedagogicos",
    name: "Crud-Encontros-Pedagogicos",
    component: () =>
      import("../pages/conteudo/crud/encontrosPedagogicos/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
    props: {
      tipo: "encontro",
    },
  },
  {
    path: prefixoConteudo + "/crud/informacoes",
    name: "Crud-Informacoes",
    component: () => import("../pages/conteudo/crud/informacoes/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
    props: {
      tipo: "informacao",
    },
  },
  {
    path: prefixoConteudo + "/crud/conteudo-apoio",
    name: "Crud-Conteudo-Apoio",
    component: () => import("../pages/conteudo/crud/conteudoApoio/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioConteudo,
    },
  },

	{
		path: prefixoConteudo + "/crud/marketing-educacional",
		name: "Crud-Marketing-Educacional",
		component: () => import("../pages/conteudo/crud/marketingEducacional/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},

	{
		path: prefixoConteudo + "/relatorios/questao-habilidade",
		name: "Relatorio-Questao-Habilidade",
		component: () => import("../pages/conteudo/relatorios/questaoHabilidade/HomeView.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},
	{
		path: prefixoConteudo + "/relatorios/questao-habilidade/atividade",
		name: "Relatorio-Questao-Habilidade-Atividade",
		component: () => import("../pages/conteudo/relatorios/questaoHabilidade/atividade/HomeView.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},
	{
		path: prefixoConteudo + "/relatorios/questao-habilidade/avaliacao",
		name: "Relatorio-Questao-Habilidade-Avaliacao",
		component: () => import("../pages/conteudo/relatorios/questaoHabilidade/avaliacao/HomeView.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioConteudo,
		},
	},
];
