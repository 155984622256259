<template>
  <v-navigation-drawer :mini-variant-width="70" app :permanent="!isMobile" :expand-on-hover="!isMobile"
    v-model="isMenuOpen" :style="{ background: 'var(--main-color)', height: '100%' }">
    <v-list>
      <div v-for="(menu, i) in menus" :key="'menu-professor-' + i">
        <list-item @modalSair="sair" v-if="!menu.group" :item="menu" />

        <list-group-item v-else :menu="menu" />
      </div>
    </v-list>

    <!--      <v-list class="px-2" v-show="privilegio == 5">-->
    <!--        <v-list-item-->
    <!--            v-for="(menu, i) in menusConteudo"-->
    <!--            :key="'menu-conteudo-' + i"-->
    <!--            @click="clickAcaoMenu(menu)"-->
    <!--            class="white&#45;&#45;text"-->
    <!--            :class="{'lista&#45;&#45;ativa': rotaAtiva(menu.rota), 'mb-3': menu.titulo === 'Relatório das Questões'}"-->
    <!--        >-->
    <!--          <v-list-item-icon class="my-auto">-->
    <!--            &lt;!&ndash; <component :is="menu.icon" color="#fff" /> &ndash;&gt;-->
    <!--            <v-img max-width="30" max-height="30" :src="menu.icon"/>-->
    <!--            <div v-if="menu.titulo === 'Relatório das Questões'" class="divisor"></div>-->
    <!--          </v-list-item-icon>-->

    <!--          <v-list-item-title class="my-2" :style="{ whiteSpace: 'normal' }">-->
    <!--            {{ menu.titulo}}-->
    <!--          </v-list-item-title>-->
    <!--        </v-list-item>-->
    <!--      </v-list>-->
    <ModalSair :modal-aberto="modalSairAberto" @close-modal="modalSairAberto = false" @sair="
    modalSairAberto = false;
  $router.push('/sair');
  " @continuar="modalSairAberto = false" />
  </v-navigation-drawer>
</template>

<script>
import ListItem from "./ListItem";
import ListGroupItem from "./ListGroupItem";
import ModalSair from "@/components/ModalSair.vue";

export default {
  name: "NavbarConteudo",
  props: ["toggleMenu"],
  components: { ListItem, ListGroupItem, ModalSair },
  data() {
    return {
      isMenuOpen: false,
      modalSairAberto: false,
      menus: [
        {
          titulo: "Questões",
          icon: require("@assets/icones/menu-modelagem.svg"),
          group: true,
          itens: [
            {
              icon: require("@assets/icones/questao-evoluir.png"),
              titulo: "Evoluir",
              rota: "/conteudo/relatorio-questoes",
              ativo: false,
            },
            {
              icon: require("@assets/icones/questao-maissaber.png"),
              titulo: "Mais Saber",
              rota: "/conteudo/mais-saber/relatorio-questoes",
              ativo: false,
            },
          ],
        },
        {
          icon: require("@assets/icones/menu-conteudos.png"),
          titulo: "Conteúdos",
          rota: "/conteudo/conteudos",
          ativo: false,
        },
        {
          icon: require("@/assets/icones/habilidade-icon.png"),
          titulo: "Habilidades",
          rota: "/conteudo/crud/habilidade",
          ativo: false,
        },
        {
          titulo: "Relatórios",
          icon: require("@assets/icones/menu-relatorios.svg"),
          group: true,
          itens: [
            {
              icon: require("@/assets/icones/menu-relatorio-questoes.png"),
              titulo: "Relatório de questões e habilidades",
              rota: "/conteudo/relatorios/questao-habilidade",
              ativo: false,
            },
          ],
        },

        {
          titulo: "Reds",
          icon: require("@assets/icones/menu-recursos-educacionais.svg"),
          group: true,
          itens: [
            {
              icon: require("@/assets/icones/recursos-podcast.svg"),
              titulo: "Podcast",
              rota: "/conteudo/crud/podcast",
              ativo: false,
            },
            {
              icon: require("@/assets/icones/recursos-audio-ingles.svg"),
              titulo: "Áudios em Inglês e Infantis",
              rota: "/conteudo/crud/audios-ingles",
              ativo: false,
            },
            {
              icon: require("@/assets/icones/recursos-infograficos.svg"),
              titulo: "Infográficos e Mapas",
              rota: "/conteudo/crud/infograficos-mapas",
              ativo: false,
            },
            {
              icon: require("@/assets/icones/recursos-sequencia-didatica.svg"),
              titulo: "Sequência Didática",
              rota: "/conteudo/crud/sequencia-didatica",
              ativo: false,
            },
            {
              icon: require("@/assets/icones/recursos-planejamento.svg"),
              titulo: "Planejamentos",
              rota: "/conteudo/crud/planejamentos",
              ativo: false,
            },
            {
              icon: require("@/assets/icones/projetos-integradores.svg"),
              titulo: "Projetos Integradores",
              rota: "/conteudo/crud/projetos-integradores",
              ativo: false,
            },
            {
              icon: require("@/assets/icones/recursos-video.svg"),
              titulo: "Videos",
              rota: "/conteudo/crud/videos",
              ativo: false,
            },
            {
              icon: require("@/assets/icones/recursos-videoaulas.svg"),
              titulo: "Videoaulas Animação",
              rota: "/conteudo/crud/videoaulas-animacoes",
              ativo: false,
            },
            {
              icon: require("@/assets/icones/menu-livro.svg"),
              titulo: "Livros",
              rota: "/conteudo/crud/livros",
              ativo: false,
            },
            {
              icon: require("@/assets/icones/recursos-infograficos.svg"),
              titulo: "Conteúdos Abertos",
              rota: "/conteudo/conteudo-aberto",
              ativo: false,
            },
          ],
        },

        {
          titulo: "Assessoria pedagógica",
          icon: require("@assets/icones/menu-assessoria.svg"),
          group: true,
          itens: [
            {
              icon: require("@/assets/icones/menu-eventos.svg"),
              titulo: "Encontros Pedagógicos",
              rota: "/conteudo/crud/encontros-pedagogicos",
              ativo: false,
            },
            {
              icon: require("@/assets/icones/menu-mensagens.svg"),
              titulo: "Informações",
              rota: "/conteudo/crud/informacoes",
              ativo: false,
            },
            {
              icon: require("@/assets/icones/menu-livro.svg"),
              titulo: "Conteúdo de Apoio",
              rota: "/conteudo/crud/conteudo-apoio",
              ativo: false,
            },
          ],
        },

        {
          titulo: "Marketing Educacional",
          icon: require("@assets/icones/recursos-sequencia-didatica.svg"),
          rota: "/conteudo/crud/marketing-educacional",
        },
        {
          titulo: "Banner",
          icon: require("@assets/icones/menu-banner.png"),
          rota: "/conteudo/crud/banner-home",
        },
        {
          titulo: "Obras",
          icon: require("@assets/icones/menu-livro.svg"),
          rota: "/conteudo/crud/obras",
        },
        {
          titulo: "Disciplinas",
          icon: require("@assets/icones/disciplinas.png"),
          rota: "/conteudo/crud/disciplinas",
        },
        {
          titulo: "Escolas",
          icon: require("@assets/icones/menu-home.svg"),
          rota: "/conteudo/crud/escolas",
        },
        {
          titulo: "Contratos",
          icon: require("@assets/icones/menu-relatorios.svg"),
          rota: "/conteudo/crud/contratos",
        },
        {
          icon: require("@assets/icones/apoio-turmas.svg"),
          titulo: "Turmas",
          rota: "/conteudo/crud/turmas",
          ativo: false,
        },
        {
          icon: require("@assets/icones/projetos-integradores.svg"),
          titulo: "Usuários",
          rota: "/conteudo/crud/usuarios",
          ativo: false,
        },
        {
          icon: require("@assets/icones/compartilhar.png"),
          titulo: "Compart. PNLD",
          rota: "/conteudo/pnld",
          ativo: false,
        },
        {
          titulo: "Sair",
          icon: require("@assets/icones/menu-sair.svg"),
          rota: "sair",
        },
      ],
    };
  },
  methods: {
    sair() {
      this.modalSairAberto = true;
    },
  },
  watch: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width <= 600;
    },
  },
  created() {
    this.isMenuOpen = this.menuOpen;
  },
};
</script>
